import {useModal} from "hooks";
import {X} from "react-feather";
import {Container} from "./styled";
import {UserModel} from "interfaces";
import {useTheme} from "styled-components";
import {Column, Typography, Row, IconButton, Avatar} from "components";

interface UserPreviewProps {
  user: UserModel;
  ticket: number;
}

export const UserPreview: React.FC<UserPreviewProps> = ({user, ticket}) => {
  const {black} = useTheme();
  const {name, lastname, picture, phone} = user;
  const {closeModal} = useModal();

  return (
    <Container>
      <Row justifyContent="space-between">
        <Typography fontSize={20} fontFamily="Poppins-SemiBold">
          Vista previa
        </Typography>
        <IconButton icon={<X color={black} />} onClick={closeModal} />
      </Row>
      <Column width="100%" gap={2} alignItems="center">
        <Avatar size={70} src={picture} marginBottom={10} />
        <Typography fontSize={16} fontFamily="Poppins-Medium">
          {name} {lastname}
        </Typography>
        <Typography fontSize={16}>{phone}</Typography>
        <Typography>Ticket reservado: #{ticket}</Typography>
      </Column>
    </Container>
  );
};

import moment from "moment";
import {useEffect} from "react";
import {Container} from "./styled";
import {useTheme} from "styled-components";
import {approve, get} from "@redux/slices/payments/thunks";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {AlertCircle, Check, Users as EmptyIcon} from "react-feather";
import {
  Table,
  Column,
  Confirm,
  Skeleton,
  Typography,
  IconButton,
  ComponentLoader,
} from "components";

interface PaymentsProps {}

export const Payments: React.FC<PaymentsProps> = () => {
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();
  const {container, secondary, white} = useTheme();
  const {payments, request} = useAppSelector("payments");

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ComponentLoader
      requestStatus={request["payments"]}
      skeleton={
        <Column gap={1}>
          <Skeleton count={14} width="100%" height={50} />
        </Column>
      }
    >
      <Container>
        <Table
          data={payments}
          columns="180px 90px 200px 110px 280px 110px 120px"
          emptyUI={{
            message: "No hay usuarios registrados aún",
            icon: <EmptyIcon size={40} />,
          }}
          head={[
            {label: "Usuario"},
            {label: "Monto"},
            {label: "Fecha"},
            {label: "Estatus"},
            {label: "Sorteo"},
            {label: "Tickets"},
            {label: "Acciones"},
          ]}
        >
          {(payment) => {
            const {
              _id,
              status,
              amount,
              fullname,
              createdAt,
              lotteryName,
              statusString,
              number: tickets,
            } = payment;
            const isApproved = status === 1;
            const ApproveIcon = isApproved ? AlertCircle : Check;
            return (
              <Table.Tr
                key={_id}
                cursor="pointer"
                marginBottom={1}
                backgroundColor={container}
              >
                <Table.Td gap={6} overflow="hidden">
                  <Typography fontFamily="Poppins-Light" fontSize={16}>
                    {fullname}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography fontFamily="Poppins-Light" fontSize={16}>
                    ${amount.toFixed(2)}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography fontFamily="Poppins-Light" fontSize={16}>
                    {moment(createdAt).format("DD/MM/YYYY hh:mm:ss")}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography fontFamily="Poppins-Light" fontSize={16}>
                    {statusString}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography
                    fontSize={16}
                    className="line-clamp-1"
                    fontFamily="Poppins-Light"
                  >
                    {lotteryName}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography fontSize={16} fontFamily="Poppins-Light">
                    {tickets.map(
                      (ticket, key, arr) =>
                        `${ticket}${
                          arr["length"] > 1 && key + 1 !== arr["length"]
                            ? ",  "
                            : ""
                        }`
                    )}
                  </Typography>
                </Table.Td>
                <Table.Td gap={6}>
                  <IconButton
                    disabled={status === 1}
                    toolTip={status === 1 ? "Pago aprobado" : "Aprobar pago"}
                    backgroundColor={secondary}
                    icon={<ApproveIcon color={white} />}
                    toolTipStyle={{backgroundColor: secondary}}
                    onClick={() => {
                      openModal(
                        <Confirm
                          title="¿Estás seguro?"
                          message={`El estado del pago de ${fullname} pasará a ser aprobado por ti. Con lo cual convalidas que el dinero ha llegado con éxito`}
                          actions={[
                            {
                              label: "Aprobar",
                              loaderId: "approve-payment",
                              leftIcon: <ApproveIcon color={white} />,
                              style: {backgroundColor: secondary},
                              onClick: () => {
                                dispatch(approve({...payment, status: 1}));
                              },
                            },
                            {
                              label: "Cancelar",
                              onClick: () => closeModal(),
                              style: {backgroundColor: "#bcbcbcec"},
                            },
                          ]}
                        />
                      );
                    }}
                  />
                </Table.Td>
              </Table.Tr>
            );
          }}
        </Table>
      </Container>
    </ComponentLoader>
  );
};

import {useEffect} from "react";
import {Container} from "./styled";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {EmojiEventsOutlined} from "@mui/icons-material";
import {AlertCircle, AlertOctagon, Gift} from "react-feather";
import {closeLottery, get} from "@redux/slices/lottery/thunks";
import {Statistics, Tickets, Winners} from "pages/Lottery/lib";
import {useAppDispatch, useAppSelector, useModal, useToggleLive} from "hooks";
import {Container as StatisticsSkeletonGrid} from "pages/Lottery/lib/Statistics/styled";
import {
  Row,
  Button,
  Column,
  Confirm,
  Skeleton,
  Accordion,
  Typography,
  ReportWinner,
  ComponentLoader,
} from "components";

interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const push = useNavigate();
  const dispatch = useAppDispatch();
  const {toggleLive} = useToggleLive();
  const {openModal, closeModal} = useModal();
  const {lotteries, request} = useAppSelector("lottery");
  const {secondary, white, heart, black} = useTheme();
  const currentLotteries = lotteries.filter(({status}) => status === 1);

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ComponentLoader
        requestStatus={request["lotteries"]}
        emptyUI={{
          data: currentLotteries,
          message: "Actualmente no hay sorteos En Curso",
          icon: <AlertOctagon size={40} strokeWidth={2} color={black} />,
        }}
        skeleton={
          <Column width="100%">
            <Skeleton width="30%" height={45} borderRadius={100} />
            <Column width="100%">
              <Skeleton width={150} height={35} borderRadius={100} />
              <Row>
                <Skeleton
                  count={3}
                  width={150}
                  height={45}
                  overflow="hidden"
                  borderRadius={100}
                />
              </Row>
              <StatisticsSkeletonGrid>
                <Skeleton count={5} height={150} />
              </StatisticsSkeletonGrid>
              <Column width="100%" gap={1}>
                <Skeleton count={5} width="100%" height={50} />
              </Column>
            </Column>
          </Column>
        }
      >
        {currentLotteries.map(({title, number, _id, status}, key, list) => (
          <Column key={key}>
            <Typography fontFamily="Poppins-SemiBold" fontSize={22}>
              Sorteo #{number} - {title}
            </Typography>
            <Column>
              <Typography>Accesos directos</Typography>
              <Row>
                <Button
                  label="Reportar ganador"
                  onClick={() =>
                    openModal(<ReportWinner lottery={list[key]} />)
                  }
                  leftIcon={
                    <EmojiEventsOutlined style={{color: white, fontSize: 20}} />
                  }
                />
                <Button
                  leftIcon={<Gift color={white} size={20} />}
                  style={{backgroundColor: secondary}}
                  label="Ver premios"
                  onClick={() => push(`/history/lottery/${_id}#awards`)}
                />
                <Button
                  label="Finalizar sorteo"
                  style={{backgroundColor: heart}}
                  leftIcon={<AlertCircle color={white} size={20} />}
                  onClick={() => {
                    openModal(
                      <Confirm
                        title="¿Seguro que deseas finalizar el sorteo?"
                        message="Una vez finalizado ya no podrán comprar más tickets los usuarios"
                        actions={[
                          {
                            label: "Finalizar",
                            loaderId: "close-lottery",
                            style: {backgroundColor: heart},
                            onClick: () => {
                              dispatch(closeLottery(_id as string)).then(() => {
                                toggleLive("0");
                              });
                            },
                          },
                          {
                            label: "Cancelar",
                            onClick: () => closeModal(),
                            style: {backgroundColor: "#bcbcbcec"},
                          },
                        ]}
                      />
                    );
                  }}
                />
              </Row>
            </Column>
            <Statistics lottery={list[key]} />
            <Accordion
              items={[
                {
                  header: "Ganadores",
                  tag: `winners-${number}`,
                  content: <Winners lottery={list[key]} />,
                },
                {
                  header: "Tickets",
                  tag: `tickets-${number}`,
                  content: <Tickets lottery={list[key]} />,
                },
              ]}
            />
          </Column>
        ))}
      </ComponentLoader>
    </Container>
  );
};

import {CSSProperties} from "react";
import {ColorRing, Style} from "react-loader-spinner";

interface SpinnerLoaderProps {
  size?: number;
  style?: CSSProperties;
}

export const SpinnerLoader: React.FC<SpinnerLoaderProps> = ({
  size = 50,
  style,
}) => {
  return (
    <ColorRing
      width={size}
      height={size}
      wrapperStyle={style as Style}
      colors={["#8ecf00", "#99d319", "#a4d832", "#afdd4c", "#bbe266"]}
    />
  );
};

import {approve, get} from "./thunks";
import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";

export const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(get.pending, function (state) {
      state["request"]["payments"] = setRequestStatus("initial");
    });
    builder.addCase(get.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["payments"] = data;
      state["request"]["payments"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["payments"] = setRequestStatus("error");
    });

    builder.addCase(approve.fulfilled, function (state, action) {
      const updated = action.payload;
      const paymentsUpdated = state["payments"];
      const index = paymentsUpdated.findIndex(
        ({_id}) => _id === updated["_id"]
      );
      paymentsUpdated[index] = updated;
      state["payments"] = paymentsUpdated;
    });
  },
});

export const {reducer: payments} = paymentSlice;

import {useEffect} from "react";
import {useAppDispatch} from "hooks";
import {Fragment} from "react/jsx-runtime";
import {get, getRate} from "@redux/slices/config/thunks";
import {Modal, Alert, FullScreenLoader, ReduxNavigation} from "components";

interface GlobalProps {}

export const Global: React.FC<GlobalProps> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(get());
    dispatch(getRate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Alert />
      <Modal />
      <ReduxNavigation />
      <FullScreenLoader />
    </Fragment>
  );
};

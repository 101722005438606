import * as Yup from "yup";
import {CreateCountryPayload} from "interfaces";

export const initialValues: CreateCountryPayload = {
  name: "",
  code: "",
  prefix: "",
};

export const createCountrySchema = Yup.object().shape({
  name: Yup.string().required("Este campo es obligatorio"),
  code: Yup.string().required("Este campo es obligatorio"),
  prefix: Yup.string().required("Este campo es obligatorio"),
});

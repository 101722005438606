import moment from "moment";
import {useEffect} from "react";
import {Container} from "./styled";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {get, remove} from "@redux/slices/lottery/thunks";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {Eye, Trash, Award, RotateCcw, ArrowRight} from "react-feather";
import {
  Table,
  Column,
  Status,
  Button,
  Confirm,
  Skeleton,
  Typography,
  IconButton,
  EmptyUIState,
  ComponentLoader,
} from "components";

interface LotteriesProps {}

export const Lotteries: React.FC<LotteriesProps> = () => {
  const push = useNavigate();
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();
  const {lotteries, request} = useAppSelector("lottery");
  const {white, heart, container} = useTheme();

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ComponentLoader
      requestStatus={request["lotteries"]}
      skeleton={
        <Column gap={1}>
          <Skeleton count={8} width="100%" height={40} />
        </Column>
      }
    >
      <Container>
        <Table
          style={{backgroundColor: container}}
          data={lotteries}
          columns="50px 250px 80px 120px 120px 120px 120px 120px 120px 100px 110px"
          head={[
            {label: "Nro."},
            {label: "Título"},
            {label: "Precio"},
            {label: "Disponibles"},
            {label: "Vendidos"},
            {label: "Creación"},
            {label: "Inicio"},
            {label: "Cierre"},
            {label: "Premios"},
            {label: "Estado"},
            {label: "Acciones"},
          ]}
          emptyUI={{
            message: "No hay registros de ningún sorteo creado",
            icon: <RotateCcw size={40} />,
          }}
        >
          {({
            _id,
            title,
            price,
            number,
            awards,
            status,
            end_date,
            createdAt,
            start_date,
            numbersArray,
            totalTicketsSold,
          }) => (
            <Table.Tr marginBottom={lotteries["length"] > 1 ? 3 : 0}>
              <Table.Td gap={6} overflow="hidden">
                <Typography fontFamily="Poppins-Medium">#{number}</Typography>
              </Table.Td>
              <Table.Td>
                <Typography className="line-clamp-1">{title}</Typography>
              </Table.Td>
              <Table.Td>
                <Typography>${price}</Typography>
              </Table.Td>
              <Table.Td>
                <Typography>{numbersArray["length"]} Tickets</Typography>
              </Table.Td>
              <Table.Td>
                <Typography>{totalTicketsSold} Tickets</Typography>
              </Table.Td>
              <Table.Td>
                <Typography>
                  {moment(createdAt).format("DD/MM/YYYY")}
                </Typography>
              </Table.Td>
              <Table.Td>
                <Typography>
                  {moment(start_date).format("DD/MM/YYYY")}
                </Typography>
              </Table.Td>
              <Table.Td>
                <Typography>{moment(end_date).format("DD/MM/YYYY")}</Typography>
              </Table.Td>
              <Table.Td>
                <EmptyUIState
                  data={awards}
                  icon={<Award />}
                  style={{width: "100%"}}
                  message="No hay premios asignados a este sorteo"
                  body={
                    <Button
                      label="Cargar premios"
                      rightIcon={<ArrowRight color={white} />}
                      onClick={() => push(`/history/lottery/${_id}#awards`)}
                    />
                  }
                >
                  <Typography>{awards["length"]} premios</Typography>
                </EmptyUIState>
              </Table.Td>
              <Table.Td>
                <Status status={status} />
              </Table.Td>
              <Table.Td gap={6}>
                <IconButton
                  icon={<Eye />}
                  toolTip="Ver sorteo"
                  onClick={() => push(`/history/lottery/${_id}`)}
                />
                <IconButton
                  toolTip="Eliminar"
                  backgroundColor={heart}
                  loaderId="remove-lottery"
                  icon={<Trash color={white} />}
                  toolTipStyle={{backgroundColor: heart}}
                  onClick={() => {
                    openModal(
                      <Confirm
                        title="¿Seguro que deseas eliminarlo?"
                        message="Ya no podrás revertir esta acción"
                        actions={[
                          {
                            label: "Eliminar",
                            loaderId: "remove-lottery",
                            leftIcon: <Trash color={white} />,
                            style: {backgroundColor: heart},
                            onClick: () => dispatch(remove(_id as string)),
                          },
                          {
                            label: "Cancelar",
                            onClick: () => closeModal(),
                            style: {backgroundColor: "#bcbcbcec"},
                          },
                        ]}
                      />
                    );
                  }}
                />
              </Table.Td>
            </Table.Tr>
          )}
        </Table>
      </Container>
    </ComponentLoader>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  gap: 2rem;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #030303;
`;

export const Dialog = styled.div`
  width: 420px;
  padding: 0.8rem;
  border-radius: 5px;
  background-color: #fff;
`;

import {useState} from "react";
import {Container} from "./styled";
import {useAppDispatch} from "hooks";
import {LotteryModel} from "interfaces";
import {insertMedia} from "@redux/slices/lottery/thunks";
import {Button, Column, Multimedia as UIMultimedia} from "components";

interface MultimediaProps {
  lottery: LotteryModel;
}

export const Multimedia: React.FC<MultimediaProps> = ({lottery}) => {
  const {media} = lottery;
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);

  return (
    <Container>
      <Column width="100%">
        <UIMultimedia
          media={media}
          onNewMedia={(files) => {
            setFiles(files);
            setDisabled(!files["length"]);
          }}
        />
        <Button
          disabled={disabled}
          loaderId="insert-media"
          label="Guardar cambios"
          onClick={() => {
            const formData = new FormData();
            files.forEach((file) => {
              formData.append("files", file);
            });
            dispatch(insertMedia(formData));
          }}
        />
      </Column>
    </Container>
  );
};

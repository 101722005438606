import {Formik} from "formik";
import {Container} from "./styled";
import {buildSelectOptions} from "utils";
import {useAppDispatch, useAppSelector} from "hooks";
import {createCountrySchema, initialValues} from "./form.schema";
import {Input, Column, Button, Typography, Select} from "components";

interface StateFormProps {}

export const StateForm: React.FC<StateFormProps> = () => {
  const dispatch = useAppDispatch();
  const {countries} = useAppSelector("location");

  return (
    <Container>
      <Column>
        <Column width="100%" gap={0}>
          <Typography fontSize={20} fontFamily="Poppins-SemiBold">
            Nuevo Estado
          </Typography>
        </Column>
        <Formik
          initialValues={initialValues}
          validationSchema={createCountrySchema}
          onSubmit={(payload) => {}}
        >
          {({
            dirty,
            errors,
            values,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column gap={0} width="100%">
                <Select
                  name="countryId"
                  value={values["countryId"]}
                  touched={touched["countryId"]}
                  label="Desde donde envió el dinero"
                  onBlur={handleBlur("countryId")}
                  onChange={handleChange("countryId")}
                  helperErrorText={errors["countryId"]}
                  options={buildSelectOptions(countries, "Selecciona un país", {
                    keyValue: "_id",
                    keyLabel: "name",
                  })}
                />
                <Input
                  name="name"
                  label="Nombre"
                  value={values["name"]}
                  touched={touched["name"]}
                  placeholder="Lara"
                  onBlur={handleBlur("name")}
                  onChange={handleChange("name")}
                  helperErrorText={errors["name"]}
                />
                <Input
                  name="code"
                  label="Código"
                  placeholder="LA"
                  value={values["code"]}
                  touched={touched["code"]}
                  onBlur={handleBlur("code")}
                  onChange={handleChange("code")}
                  helperErrorText={errors["code"]}
                />
                <Column width="100%" alignItems="center" marginTop={18}>
                  <Button
                    type="submit"
                    label="Crear"
                    disabled={isDisabled}
                    style={{width: "100%"}}
                    loaderId="create-country"
                    onClick={() => handleSubmit()}
                  />
                </Column>
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};

import * as Yup from "yup";
import {ConfigKey, ConfigModel} from "interfaces";

export const initialValues: ConfigModel = {
  label: "",
  value: "",
  key: "" as ConfigKey,
};

export const createLotterySchema = Yup.object().shape({
  key: Yup.string().required("Este campo es obligatorio"),
  label: Yup.string().required("Este campo es obligatorio"),
  value: Yup.string().required("Este campo es obligatorio"),
});

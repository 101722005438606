import {api} from "api";
import {toggleButtonLoaderId} from "../layout";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkSuccess} from "@redux/thunk-success";
import {thunkBadRequest} from "@redux/thunk-error";
import {LoginPayload, Authenticated} from "interfaces";

export const login = createAsyncThunk(
  "auth/login",
  async (payload: LoginPayload, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("login"));

    try {
      const authenticated = await api.Post<Authenticated>(
        "/users/login",
        payload
      );
      if (authenticated["data"]["role"] !== 1) {
        throw new Error("Necesitas ser administrador para poder ingresar");
      }

      thunkSuccess(dispatch, {title: "Inicio de sesión con éxito"});
      return authenticated;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("login"));
    }
  }
);

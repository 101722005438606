import {FC, useEffect} from "react";
import {Skeleton} from "./Skeleton";
import {LotteryModel} from "interfaces";
import {resetLottery} from "@redux/slices";
import {useTheme} from "styled-components";
import {getInitialEnteredIndex} from "./helper";
import {getOne} from "@redux/slices/lottery/thunks";
import {ArrowLeft, Edit, Upload} from "react-feather";
import {EmojiEventsOutlined} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {NewTestimonial} from "components/@modal/NewTestimonial";
import {Awards, Info, Multimedia, Statistics, Tickets, Winners} from "./lib";
import {
  Row,
  Column,
  Accordion,
  Typography,
  IconButton,
  NewLottery,
  ComponentLoader,
  AccordionItemProps,
  ReportWinner,
} from "components";

export type SectionItem = Omit<AccordionItemProps, "content"> & {
  content: FC<{lottery: LotteryModel}>;
};

const items: SectionItem[] = [
  {
    tag: "general-info",
    content: Info,
    header: "Información general",
    initialEntered: true,
  },
  {
    tag: "multimedia",
    content: Multimedia,
    header: "Multimedia",
  },
  {
    tag: "winners",
    content: Winners,
    header: "Ganadores",
  },
  {
    tag: "awards",
    content: Awards,
    header: "Premios",
  },
  {
    tag: "tickets",
    content: Tickets,
    header: "Tickets",
  },
];

interface LotteryProps {}

export const Lottery: React.FC<LotteryProps> = () => {
  const push = useNavigate();
  const {black} = useTheme();
  const {openModal} = useModal();
  const {lotteryId} = useParams();
  const dispatch = useAppDispatch();
  const {lottery, request} = useAppSelector("lottery");
  const {
    _id,
    price,
    title,
    awards,
    numbers,
    end_date,
    draw_date,
    start_date,
    description,
  } = lottery;

  useEffect(() => {
    dispatch(getOne(lotteryId as string));
    return () => {
      dispatch(resetLottery());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryId]);

  return (
    <ComponentLoader requestStatus={request["lottery"]} skeleton={<Skeleton />}>
      <Column gap={10} maxWidth="1350px" margin="0 auto">
        <Row marginBottom={10}>
          <IconButton
            onClick={() => push(-1)}
            icon={<ArrowLeft size={20} color={black} />}
          />
          <Typography fontSize={20} fontFamily="Poppins-Medium">
            Información de {title}
          </Typography>
          <IconButton
            size={40}
            toolTipPosition="down"
            toolTip="Editar sorteo"
            icon={<Edit size={20} color={black} />}
            onClick={() =>
              openModal(
                <NewLottery
                  initialState={{
                    price,
                    title,
                    numbers,
                    awards,
                    end_date,
                    draw_date,
                    start_date,
                    description,
                    id: _id as string,
                  }}
                />
              )
            }
          />
          <IconButton
            size={40}
            toolTipPosition="down"
            toolTip="Subir testimonio"
            icon={<Upload size={20} color={black} />}
            onClick={() => openModal(<NewTestimonial lottery={lottery} />)}
          />
          <IconButton
            size={40}
            toolTipPosition="down"
            toolTip="Reportar ganador"
            icon={<EmojiEventsOutlined style={{color: black, fontSize: 20}} />}
            onClick={() => openModal(<ReportWinner lottery={lottery} />)}
          />
        </Row>
        <Statistics lottery={lottery} />
        <Accordion
          initialEnteredItemIndex={getInitialEnteredIndex(items)}
          items={items.map(({content: Component, ...data}) => ({
            ...data,
            content: <Component lottery={lottery} />,
          }))}
        />
      </Column>
    </ComponentLoader>
  );
};

import {LotteryModel} from "interfaces";
import {dateDiff, dateLeft} from "utils";
import {useTheme} from "styled-components";
import {Column, Typography} from "components";
import {Container, Statistic} from "./styled";
import {CircularProgressbar} from "react-circular-progressbar";

interface StatisticsProps {
  lottery: LotteryModel;
}

export const Statistics: React.FC<StatisticsProps> = ({lottery}) => {
  const {primary, white} = useTheme();
  const {price, numbers, end_date, start_date, totalTicketsSold} = lottery;
  return (
    <Column width="100%">
      <Typography fontSize={18} fontFamily="Poppins-SemiBold">
        Resumen
      </Typography>
      <Container>
        <Statistic>
          <Column justifyContent="center" alignItems="center">
            <CircularProgressbar
              circleRatio={1}
              maxValue={numbers}
              value={totalTicketsSold}
              text={`${totalTicketsSold}/${numbers}`}
              styles={{
                text: {fill: primary},
                path: {stroke: primary},
                root: {width: 75, height: 75},
              }}
            />
          </Column>
          <Typography color={white} fontSize={16.5} textAlign="center">
            Tickets vendidos
          </Typography>
        </Statistic>
        <Statistic>
          <Column gap={0} justifyContent="center" alignItems="center">
            <Typography color={white} fontFamily="Poppins-Medium" fontSize={32}>
              ${(totalTicketsSold * price).toLocaleString("US")}
            </Typography>
            <Typography color={white} fontSize={14}>
              1 ticket = ${price}
            </Typography>
          </Column>
          <Typography color={white} fontSize={16.5} textAlign="center">
            Total de ganancias (USD)
          </Typography>
        </Statistic>
        <Statistic>
          <Column gap={0} justifyContent="center" alignItems="center">
            <Typography color={white} fontFamily="Poppins-Medium" fontSize={32}>
              Bs.{" "}
              {(totalTicketsSold * price * 36.59).toFixed(2).toLocaleString()}
            </Typography>
            <Typography color={white} fontSize={14}>
              1 ticket = Bs {(price * 36.59).toLocaleString("US")}
            </Typography>
          </Column>
          <Typography color={white} fontSize={16.5} textAlign="center">
            Total de ganancias (Bs)
          </Typography>
        </Statistic>
        <Statistic>
          <Column gap={0} justifyContent="center" alignItems="center">
            <Typography
              color={white}
              fontSize={18}
              textAlign="center"
              fontFamily="Poppins-Medium"
            >
              {dateDiff(start_date)}
            </Typography>
          </Column>
          <Typography color={white} fontSize={16.5} textAlign="center">
            Tiempo transcurrido
          </Typography>
        </Statistic>
        <Statistic>
          <Column gap={0} justifyContent="center" alignItems="center">
            <Typography
              color={white}
              fontSize={18}
              textAlign="center"
              fontFamily="Poppins-Medium"
            >
              {dateLeft(end_date)}
            </Typography>
          </Column>
          <Typography color={white} fontSize={16.5} textAlign="center">
            Tiempo restante
          </Typography>
        </Statistic>
      </Container>
    </Column>
  );
};

import {api} from "api";
import {close} from "../modal";
import {PaymentModel} from "interfaces";
import {toggleButtonLoaderId} from "../layout";
import {thunkSuccess} from "@redux/thunk-success";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";

export const get = createAsyncThunk(
  "payments/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<PaymentModel[]>(
        "/payments/getAll?limit=1000&position=0"
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const approve = createAsyncThunk(
  "payments/approve",
  async (payload: PaymentModel, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("approve-payment"));
    try {
      const {data} = await api.Put<PaymentModel>(
        `/payments/update/${payload["_id"]}`,
        payload
      );
      dispatch(close());
      thunkSuccess(dispatch, {title: "Estado del pago actualizado con éxito"});
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("approve-payment"));
    }
  }
);

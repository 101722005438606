import {Plus} from "react-feather";
import {useAppDispatch} from "hooks";
import {LotteryModel} from "interfaces";
import {Container, Grid} from "./styled";
import {useTheme} from "styled-components";
import {useCallback, useState} from "react";
import {AwardCRUD} from "pages/Lottery/lib";
import {onChangeCharacteristic} from "./helper";
import {insertAward} from "@redux/slices/lottery/thunks";
import {
  Row,
  Line,
  Input,
  Column,
  Button,
  Textarea,
  Multimedia,
  Typography,
  IconButton,
} from "components";

interface NewAwardProps {
  lottery: LotteryModel;
  initialState: AwardCRUD;
}

export const NewAward: React.FC<NewAwardProps> = ({lottery, initialState}) => {
  const dispatch = useAppDispatch();
  const {white, primary} = useTheme();
  const [award, setAward] = useState<AwardCRUD>(initialState);
  const {title, description, position, characteristics, media} = award;

  const onChange = useCallback(
    ({
      key,
      value,
    }: {
      key: keyof AwardCRUD;
      value: AwardCRUD[keyof AwardCRUD];
    }) => {
      setAward((prev) => ({...prev, [key]: value}));
    },
    []
  );

  return (
    <Container>
      <Typography fontSize={17} fontFamily="Poppins-Medium">
        Insertar premio en {lottery["title"]}
      </Typography>
      <Grid>
        <Column>
          <Multimedia
            media={media}
            onNewMedia={(files) => onChange({key: "files", value: files})}
          />
        </Column>
        <Column maxHeight={600} overflowY="auto">
          <Column width="100%" gap={0}>
            <Input
              type="number"
              label="# Posición"
              placeholder="Posición"
              defaultValue={position}
              containerStyle={{width: "150px"}}
              onChange={({target}) =>
                onChange({key: "position", value: target["value"]})
              }
            />
            <Input
              label="Título"
              defaultValue={title}
              placeholder="Moto SBR"
              onChange={({target}) =>
                onChange({key: "title", value: target["value"]})
              }
            />
            <Textarea
              label="Descripción"
              defaultValue={description}
              placeholder="Una moto con excelentes prestaciones y un desempeño optimo"
              onChange={({target}) =>
                onChange({
                  key: "description",
                  value: target["value"],
                })
              }
            />
          </Column>
          <Column width="100%">
            <Row gap={10}>
              <Typography fontFamily="Poppins-Medium" fontSize={16}>
                ({characteristics["length"]}) Características
              </Typography>
              <IconButton
                size={30}
                backgroundColor={primary}
                icon={<Plus size={23} color={white} />}
                onClick={() => {
                  onChange({
                    key: "characteristics",
                    value: [...characteristics, {title: "", description: ""}],
                  });
                }}
              />
            </Row>
            {characteristics.map(
              ({title, description}, characteristicIndex, arr) => (
                <Column gap={0} width="100%" key={characteristicIndex}>
                  <Input
                    defaultValue={title}
                    label={`Título #${characteristicIndex + 1}`}
                    placeholder={`Título #${characteristicIndex + 1}`}
                    onChange={({target}) => {
                      const updated = onChangeCharacteristic(
                        arr,
                        characteristicIndex,
                        target["value"],
                        "title"
                      );
                      onChange({
                        key: "characteristics",
                        value: updated,
                      });
                    }}
                  />
                  <Textarea
                    defaultValue={description}
                    placeholder={`Descripción #${characteristicIndex + 1}`}
                    onChange={({target}) => {
                      const updated = onChangeCharacteristic(
                        arr,
                        characteristicIndex,
                        target["value"],
                        "description"
                      );
                      onChange({
                        key: "characteristics",
                        value: updated,
                      });
                    }}
                  />
                  <Line margin=".5rem auto 0" width="60%" />
                </Column>
              )
            )}
          </Column>
        </Column>
      </Grid>
      <Line />
      <Row width="100%" justifyContent="flex-end">
        <Button
          label="Guardar premio"
          loaderId="insert-award"
          onClick={async () => {
            const {files = [], characteristics} = award;
            const formData = new FormData();
            formData.append("title", award["title"]);
            formData.append("description", award["description"]);
            formData.append("position", award["position"]);
            files.forEach((file) => {
              formData.append("files", file);
            });
            characteristics.forEach(({title, description}, index) => {
              formData.append(`characteristics[${index}][title]`, title);
              formData.append(
                `characteristics[${index}][description]`,
                description
              );
            });

            dispatch(insertAward(formData));
          }}
        />
      </Row>
    </Container>
  );
};

import {Formik} from "formik";
import {useEffect} from "react";
import {Container} from "./styled";
import {useAppDispatch} from "hooks";
import {buildSelectOptions} from "utils";
import {addTestimonial, get} from "@redux/slices/lottery/thunks";
import {createLotterySchema, initialValues} from "./form.schema";
import {AwardModel, LotteryModel, NewTestimonialPayload} from "interfaces";
import {
  Column,
  Button,
  Select,
  Textarea,
  Typography,
  Multimedia,
} from "components";

interface NewTestimonialProps {
  lottery: LotteryModel;
  initialState?: NewTestimonialPayload & {id: string};
}

export const NewTestimonial: React.FC<NewTestimonialProps> = ({
  lottery,
  initialState,
}) => {
  const dispatch = useAppDispatch();
  const editable = typeof initialState !== "undefined";
  const awardDisplayOptions: (AwardModel & {displayLabel: string})[] = lottery[
    "awards"
  ].map((obj) => ({
    ...obj,
    displayLabel: `#${obj["position"]} — ${obj["title"]}`,
  }));

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Column>
        <Column width="100%" gap={0}>
          <Typography fontSize={20} fontFamily="Poppins-SemiBold">
            {editable ? "Editar" : "Nuevo"} testimonio
          </Typography>
        </Column>
        <Formik
          validationSchema={createLotterySchema}
          initialValues={initialState ?? initialValues}
          onSubmit={({message, files, awardId}) => {
            const formData = new FormData();
            formData.append("message", message);
            files.forEach((file) => {
              formData.append("files", file);
            });

            if (editable) {
              // dispatch(update({...payload, id: initialState["id"]}));
            } else {
              dispatch(addTestimonial({data: formData, awardId: awardId}));
            }
          }}
        >
          {({
            dirty,
            errors,
            values,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column gap={0} width="100%">
                <Multimedia
                  media={[]}
                  onNewMedia={(files) => setFieldValue("files", files)}
                />
                <Select
                  name="awardId"
                  label="A cual premio corresponde este testimonio"
                  value={values["awardId"]}
                  touched={touched["awardId"]}
                  onBlur={handleBlur("awardId")}
                  containerStyle={{marginTop: 15}}
                  onChange={handleChange("awardId")}
                  helperErrorText={errors["awardId"]}
                  options={buildSelectOptions(
                    awardDisplayOptions,
                    "Selecciona el premio",
                    {keyValue: "_id", keyLabel: "displayLabel"}
                  )}
                />
                <Textarea
                  name="message"
                  label="Mensaje"
                  value={values["message"]}
                  touched={touched["message"]}
                  onBlur={handleBlur("message")}
                  onChange={handleChange("message")}
                  helperErrorText={errors["message"]}
                  placeholder="Centro de Apuestas espera que lo disfrutes"
                />
                <Column width="100%" alignItems="center" marginTop={18}>
                  <Button
                    type="submit"
                    disabled={isDisabled}
                    style={{width: "100%"}}
                    loaderId="add-testimonial"
                    onClick={() => handleSubmit()}
                    label={editable ? "Actualizar" : "Agregar"}
                  />
                </Column>
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  width: 700px;
  padding: 2rem 1rem;
  border-radius: 10px;
  background-color: ${({theme}) => theme["container"]};

  ${({theme}) => theme.breakpoints.up("sm")} {
    width: 750px;
    padding: 1.5rem 1rem 1rem;
  }
`;

export const Grid = styled.div`
  gap: 1rem;
  width: 100%;
  display: grid;
  margin-top: 1rem;
  grid-template-columns: 0.7fr 1fr;
`;

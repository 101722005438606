import {api} from "api";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";
import {CityModel, CountryModel, StateModel} from "interfaces";

export const getCountries = createAsyncThunk(
  "location/get-countries",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<CountryModel[]>(
        "/geographical-division/country/getAll?limit=10&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const getStates = createAsyncThunk(
  "location/get-states",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<StateModel[]>(
        "/geographical-division/state/getAll/66c6a50874257a8560c524d3?limit=10&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const getCities = createAsyncThunk(
  "location/get-cities",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<CityModel[]>(
        "/geographical-division/city/getAll/66c6a53774257a8560c524d7?limit=10&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

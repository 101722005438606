import {useModal} from "hooks";
import {Container, Grid} from "./styled";
import {useTheme} from "styled-components";
import {Gift, Plus, Trash} from "react-feather";
import {AwardModel, LotteryModel} from "interfaces";
import {Dot} from "components/@app-layout/Layout/lib/TopBar/styled";
import {
  Row,
  Line,
  Column,
  Button,
  NewAward,
  IconButton,
  Multimedia,
  Typography,
  EmptyUIState,
} from "components";

export type AwardCRUD = AwardModel & {files?: File[]};

const newAward: AwardCRUD = {
  title: "",
  media: [],
  files: [],
  number: null,
  position: "",
  description: "",
  characteristics: [{title: "", description: ""}],
};

interface AwardsProps {
  lottery: LotteryModel;
}

export const Awards: React.FC<AwardsProps> = ({lottery}) => {
  const {awards} = lottery;
  const {openModal} = useModal();
  const {white, secondary, primary, heart, black} = useTheme();

  return (
    <Column width="100%">
      <Row gap={10}>
        <Row gap={10}>
          <Typography fontSize={18} fontFamily="Poppins-SemiBold">
            ({awards["length"]})
          </Typography>
          <IconButton
            size={35}
            backgroundColor={primary}
            icon={<Plus size={25} color={white} />}
            onClick={() => {
              openModal(<NewAward lottery={lottery} initialState={newAward} />);
            }}
          />
        </Row>
        <Dot />
      </Row>
      <EmptyUIState
        data={awards}
        icon={<Gift size={45} color={black} />}
        style={{width: "100%", padding: 0}}
        message="Aún no has agregado premios a este sorteo. Presiona en el más (+) para hacerlo"
      >
        <Grid>
          {awards.map(
            (
              {title, description, media, position, characteristics},
              key,
              arr
            ) => (
              <Column key={key} style={{transform: "rotateX(180deg)"}}>
                <Row width="100%" justifyContent="space-between">
                  <Typography fontSize={18} fontFamily="Poppins-Medium">
                    Premio al ganador #{position}
                  </Typography>
                  <Row>
                    {arr["length"] > 1 && (
                      <IconButton
                        backgroundColor={heart}
                        icon={<Trash color={white} />}
                        onClick={() => {}}
                      />
                    )}
                    <Button
                      label="Actualizar"
                      onClick={() => {}}
                      style={{backgroundColor: secondary}}
                    />
                  </Row>
                </Row>
                <Container>
                  <Multimedia type="view-only" media={media} />
                  <Column marginTop={15} width="100%">
                    <Column gap={0}>
                      <Typography fontFamily="Poppins-Medium">
                        {title}
                      </Typography>
                      <Typography fontFamily="Poppins-Light" fontSize={13}>
                        Título
                      </Typography>
                    </Column>
                    <Column gap={0}>
                      <Typography fontFamily="Poppins-Medium">
                        {description}
                      </Typography>
                      <Typography fontFamily="Poppins-Light" fontSize={13}>
                        Descripción
                      </Typography>
                    </Column>
                  </Column>
                  <Line />
                  <Column width="100%">
                    <Row gap={10}>
                      <Typography fontFamily="Poppins-Medium" fontSize={16}>
                        Características
                      </Typography>
                    </Row>
                    {characteristics.map(
                      ({title, description}, characteristicIndex, arr) => (
                        <Column gap={0} width="100%" key={characteristicIndex}>
                          <Column gap={0}>
                            <Typography fontFamily="Poppins-Medium">
                              {title}
                            </Typography>
                            <Typography
                              fontFamily="Poppins-Light"
                              fontSize={13}
                            >
                              {`Título #${characteristicIndex + 1}`}
                            </Typography>
                          </Column>
                          <Column gap={0}>
                            <Typography fontFamily="Poppins-Medium">
                              {description}
                            </Typography>
                            <Typography
                              fontFamily="Poppins-Light"
                              fontSize={13}
                            >
                              {`Descripción #${characteristicIndex + 1}`}
                            </Typography>
                          </Column>

                          {arr["length"] !== key + 1 && (
                            <Line margin=".5rem auto 0" width="60%" />
                          )}
                        </Column>
                      )
                    )}
                  </Column>
                </Container>
              </Column>
            )
          )}
        </Grid>
      </EmptyUIState>
    </Column>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  z-index: 2;
  gap: 0.5rem;
  display: flex;
  padding: 1rem 2rem;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.05);
  background-color: ${({theme}) => theme["white"]};
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: #e5d73c;
`;

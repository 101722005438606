import {Formik} from "formik";
import {Container} from "./styled";
import {useAppDispatch} from "hooks";
import {Input, Column, Button, Typography} from "components";
import {createCountrySchema, initialValues} from "./form.schema";

interface CountryFormProps {}

export const CountryForm: React.FC<CountryFormProps> = () => {
  const dispatch = useAppDispatch();

  return (
    <Container>
      <Column>
        <Column width="100%" gap={0}>
          <Typography fontSize={20} fontFamily="Poppins-SemiBold">
            Nuevo país
          </Typography>
        </Column>
        <Formik
          initialValues={initialValues}
          validationSchema={createCountrySchema}
          onSubmit={(payload) => {}}
        >
          {({
            dirty,
            errors,
            values,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column gap={0} width="100%">
                <Input
                  name="name"
                  label="Nombre"
                  value={values["name"]}
                  touched={touched["name"]}
                  placeholder="Venezuela"
                  onBlur={handleBlur("name")}
                  onChange={handleChange("name")}
                  helperErrorText={errors["name"]}
                />
                <Input
                  name="code"
                  label="Código"
                  placeholder="VE"
                  value={values["code"]}
                  touched={touched["code"]}
                  onBlur={handleBlur("code")}
                  onChange={handleChange("code")}
                  helperErrorText={errors["code"]}
                />
                <Input
                  name="prefix"
                  label="Prefijo"
                  placeholder="+58"
                  value={values["prefix"]}
                  touched={touched["prefix"]}
                  onBlur={handleBlur("prefix")}
                  onChange={handleChange("prefix")}
                  helperErrorText={errors["prefix"]}
                />
                <Column width="100%" alignItems="center" marginTop={18}>
                  <Button
                    type="submit"
                    label="Crear"
                    disabled={isDisabled}
                    style={{width: "100%"}}
                    loaderId="create-country"
                    onClick={() => handleSubmit()}
                  />
                </Column>
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};

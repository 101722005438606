import {initialState} from "./initial-state";
import {createSlice} from "@reduxjs/toolkit";
import {setRequestStatus} from "@redux/request-status";
import {lotteryInitialState} from "@redux/initial-states";
import {get, getOne, insertAward, insertMedia} from "./thunks";

export const lotterySlice = createSlice({
  name: "lottery",
  initialState,
  reducers: {
    resetLottery: function (state) {
      state["lottery"] = lotteryInitialState;
      state["request"]["lottery"] = setRequestStatus("initial");
    },
  },
  extraReducers: function (builder) {
    builder.addCase(get.fulfilled, function (state, action) {
      const {data} = action["payload"];
      state["lotteries"] = data;
      state["request"]["lotteries"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["lotteries"] = setRequestStatus("error");
    });

    builder.addCase(getOne.fulfilled, function (state, action) {
      const {data} = action["payload"];
      state["lottery"] = data;
      state["request"]["lottery"] = setRequestStatus("success");
    });
    builder.addCase(getOne.rejected, function (state) {
      state["request"]["lottery"] = setRequestStatus("error");
    });

    builder.addCase(insertAward.fulfilled, function (state, action) {
      const lotteryUpdated = action["payload"];
      state["lottery"]["awards"] = lotteryUpdated["awards"];
    });

    builder.addCase(insertMedia.fulfilled, function (state, action) {
      const lotteryUpdated = action["payload"];
      state["lottery"]["media"] = lotteryUpdated["media"];
    });
  },
});

export const {resetLottery} = lotterySlice["actions"];
export const {reducer: lottery} = lotterySlice;

import {CSSProperties} from "react";
import {ImageStyled} from "./styled";
import {Clickable} from "interfaces";

interface ImageProps extends CSSProperties, Partial<Clickable> {
  src: string;
  size?: number;
}

export const Image: React.FC<ImageProps> = (props) => {
  const {src, size = 80, onClick, ...cssStyle} = props;
  return (
    <ImageStyled
      src={src}
      onClick={onClick}
      style={{width: size, height: size, ...cssStyle}}
    />
  );
};

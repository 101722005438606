import moment from "moment";
import {useEffect} from "react";
import {Container} from "./styled";
import {useTheme} from "styled-components";
import {get, remove} from "@redux/slices/users/thunks";
import {Users as EmptyIcon, Trash} from "react-feather";
import {LocalActivityOutlined} from "@mui/icons-material";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {
  Table,
  Image,
  Column,
  Confirm,
  Skeleton,
  Typography,
  IconButton,
  GiveCredit,
  ComponentLoader,
} from "components";

interface UsersProps {}

export const Users: React.FC<UsersProps> = () => {
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();
  const {users, request} = useAppSelector("users");
  const {container, black, heart, white} = useTheme();

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("users", users);

  return (
    <ComponentLoader
      requestStatus={request["users"]}
      skeleton={
        <Column gap={1}>
          <Skeleton count={14} width="100%" height={50} />
        </Column>
      }
    >
      <Container>
        <Table
          data={users}
          columns="180px 350px 140px 110px 160px 130px 120px"
          emptyUI={{
            message: "No hay usuarios registrados aún",
            icon: <EmptyIcon size={40} />,
          }}
          head={[
            {label: "Nombre"},
            {label: "Correo"},
            {label: "Teléfono"},
            {label: "Referidos"},
            {label: "Cupones"},
            {label: "Fecha registro"},
            {label: "Acciones"},
          ]}
        >
          {(user) => {
            const {
              _id,
              name,
              email,
              phone,
              credit,
              picture,
              createdAt,
              myReferrals,
            } = user;
            return (
              <Table.Tr
                cursor="pointer"
                marginBottom={1}
                backgroundColor={container}
              >
                <Table.Td gap={6} overflow="hidden">
                  <Image
                    width={25}
                    height={25}
                    src={picture}
                    objectFit="cover"
                    borderRadius={100}
                  />
                  <Typography
                    fontSize={14}
                    className="line-clamp-1"
                    fontFamily="Poppins-Light"
                  >
                    {name}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography fontFamily="Poppins-Light" fontSize={16}>
                    {email}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography fontFamily="Poppins-Light" fontSize={16}>
                    {phone}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography fontFamily="Poppins-Light" fontSize={16}>
                    Nro: {myReferrals["length"]}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography fontFamily="Poppins-Light" fontSize={16}>
                    {credit ? `${credit["tickets"]} Cupones` : "N/A"}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography fontFamily="Poppins-Light" fontSize={16}>
                    {moment(createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Table.Td>
                <Table.Td gap={6}>
                  <IconButton
                    loaderId="give-credit"
                    toolTip="Dar créditos"
                    onClick={() => openModal(<GiveCredit user={user} />)}
                    icon={<LocalActivityOutlined style={{color: black}} />}
                  />
                  <IconButton
                    toolTip="Eliminar"
                    backgroundColor={heart}
                    icon={<Trash color={white} />}
                    toolTipStyle={{backgroundColor: heart}}
                    onClick={() => {
                      openModal(
                        <Confirm
                          title={`Seguro que desease eliminar a ${name}?`}
                          message="Ya no podrás revertir esta acción"
                          actions={[
                            {
                              label: "Eliminar",
                              loaderId: "remove-user",
                              leftIcon: <Trash color={white} />,
                              style: {backgroundColor: heart},
                              onClick: () => dispatch(remove(_id as string)),
                            },
                            {
                              label: "Cancelar",
                              onClick: () => closeModal(),
                              style: {backgroundColor: "#bcbcbcec"},
                            },
                          ]}
                        />
                      );
                    }}
                  />
                </Table.Td>
              </Table.Tr>
            );
          }}
        </Table>
      </Container>
    </ComponentLoader>
  );
};

import moment from "moment";
import {formatDate} from "utils";
import {Container} from "./styled";
import {LotteryModel} from "interfaces";
import {Column, Status, Typography} from "components";

interface InfoProps {
  lottery: LotteryModel;
}

export const Info: React.FC<InfoProps> = ({lottery}) => {
  const {status, awards, end_date, createdAt, start_date} = lottery;

  return (
    <Column width="100%">
      <Container>
        <Column>
          <Typography fontSize={17} fontFamily="Poppins-Medium">
            {moment(createdAt).format("DD/MM/YYYY")}
          </Typography>
          <Typography>Fecha de creación</Typography>
        </Column>
        <Column>
          <Typography fontSize={17} fontFamily="Poppins-Medium">
            {moment(formatDate(start_date)).format("DD/MM/YYYY hh:mm:ss")}
          </Typography>
          <Typography>Fecha de inicio</Typography>
        </Column>
        <Column>
          <Typography fontSize={17} fontFamily="Poppins-Medium">
            {moment(formatDate(end_date)).format("DD/MM/YYYY hh:mm:ss")}
          </Typography>
          <Typography>Fecha de cierre</Typography>
        </Column>
        <Column>
          <Typography fontSize={17} fontFamily="Poppins-Medium">
            {awards["length"]}
          </Typography>
          <Typography>Cantidad de premios</Typography>
        </Column>
        <Column>
          <Status status={status} />
          <Typography>Estado del sorteo</Typography>
        </Column>
      </Container>
    </Column>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: grid;
  padding: 1rem;
  grid-gap: 1rem;
  border-radius: 5px;
  background-color: ${({theme}) => theme["container"]};
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
`;

import {Formik} from "formik";
import {useEffect} from "react";
import {Container, Grid} from "./styled";
import {useTheme} from "styled-components";
import {configSchema} from "./form.schema";
import {Key, Plus, Trash} from "react-feather";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {create, get, remove, update} from "@redux/slices/config/thunks";
import {
  Row,
  Line,
  Input,
  Button,
  Column,
  Confirm,
  NewConfig,
  IconButton,
  Typography,
} from "components";

interface ConfigProps {}

export const Config: React.FC<ConfigProps> = () => {
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();
  const {configs} = useAppSelector("config");
  const {container, white, primary, heart} = useTheme();

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Row gap={10}>
        <Typography fontFamily="Poppins-Medium" fontSize={18}>
          Configuraciones
        </Typography>
        <IconButton
          backgroundColor={primary}
          icon={<Plus color={white} />}
          onClick={() => {
            openModal(<NewConfig />);
          }}
        />
      </Row>
      <Line />
      <Grid>
        {configs.map(({label, _id}, keyIndex, arr) => (
          <Formik
            key={_id}
            enableReinitialize
            initialValues={arr[keyIndex]}
            validationSchema={configSchema}
            onSubmit={(payload) => {
              if (payload["_id"]) {
                dispatch(update(payload));
              } else {
                dispatch(create(payload));
              }
            }}
          >
            {({
              dirty,
              errors,
              values,
              isValid,
              touched,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => {
              const isDisabled = !(dirty && isValid);
              return (
                <Column width="100%" key={_id}>
                  <Row>
                    <Typography fontFamily="Poppins-Medium" fontSize={16}>
                      {label}
                    </Typography>
                    <IconButton
                      backgroundColor={heart}
                      icon={<Trash color={white} />}
                      onClick={() => {
                        openModal(
                          <Confirm
                            title={`Eliminar ${label}`}
                            message="Presiona Eliminar para continuar. Una vez eliminado no se podrá recuperar y deberás crear una nueva en caso que desees ver esta configuración en la Aplicación"
                            actions={[
                              {
                                label: "Eliminar",
                                loaderId: "remove-config",
                                style: {backgroundColor: heart},
                                onClick: () => dispatch(remove(_id as string)),
                              },
                              {
                                label: "Cancelar",
                                onClick: () => closeModal(),
                                style: {backgroundColor: "#bcbcbcec"},
                              },
                            ]}
                          />
                        );
                      }}
                    />
                  </Row>
                  <Column
                    gap={0}
                    width="100%"
                    padding={10}
                    borderRadius={5}
                    backgroundColor={container}
                  >
                    <Input
                      name="label"
                      label="Título"
                      value={values["label"]}
                      touched={touched["label"]}
                      placeholder="Configuración"
                      onBlur={handleBlur("label")}
                      onChange={handleChange("label")}
                      helperErrorText={errors["label"]}
                    />
                    <Input
                      icon={Key}
                      name="key"
                      label="Clave"
                      value={values["key"]}
                      touched={touched["key"]}
                      placeholder="Identificador"
                      onBlur={handleBlur("key")}
                      onChange={handleChange("key")}
                      helperErrorText={errors["key"]}
                    />
                    <Input
                      name="value"
                      type="number"
                      label="Valor"
                      placeholder="Valor"
                      value={values["value"]}
                      touched={touched["value"]}
                      onBlur={handleBlur("value")}
                      onChange={handleChange("value")}
                      helperErrorText={errors["value"]}
                    />
                  </Column>
                  <Button
                    label="Actualizar"
                    loaderId="new-config"
                    disabled={isDisabled}
                    onClick={() => handleSubmit()}
                  />
                </Column>
              );
            }}
          </Formik>
        ))}
      </Grid>
    </Container>
  );
};

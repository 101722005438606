import {api} from "api";
import {ConfigModel} from "interfaces";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkSuccess} from "@redux/thunk-success";
import {thunkBadRequest} from "@redux/thunk-error";
import {toggleFullScreenLoader, close, toggleButtonLoaderId} from "../";

export const get = createAsyncThunk(
  "config/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<ConfigModel[]>(
        "/config/getAll?limit=1000&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleFullScreenLoader(false));
    }
  }
);

export const create = createAsyncThunk(
  "config/create",
  async (
    payload: ConfigModel & {alertTitle?: string},
    {dispatch, rejectWithValue}
  ) => {
    dispatch(toggleButtonLoaderId("new-config"));
    let {alertTitle, ...data} = payload;
    data["value"] = String(data["value"]);

    try {
      await api.Post<ConfigModel>("/config/createOrUpdate", data);
      dispatch(get());
      dispatch(close());
      thunkSuccess(dispatch, {
        title: alertTitle ?? "Configuración creada",
      });
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("new-config"));
    }
  }
);

export const update = createAsyncThunk(
  "config/update",
  async (
    payload: ConfigModel & {alertTitle?: string},
    {dispatch, rejectWithValue}
  ) => {
    dispatch(toggleButtonLoaderId("new-config"));
    let {alertTitle, ...data} = payload;
    data["value"] = String(data["value"]);

    try {
      await api.Put<ConfigModel>(`/config/update/${data["_id"]}`, data);
      dispatch(get());
      dispatch(close());
      thunkSuccess(dispatch, {
        title: alertTitle ?? "Configuración actualizada",
      });
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("new-config"));
    }
  }
);

export const remove = createAsyncThunk(
  "config/remove",
  async (configId: string, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("remove-config"));
    try {
      await api.Delete<ConfigModel>(`/config/delete/${configId}`);
      dispatch(get());
      dispatch(close());
      thunkSuccess(dispatch, {title: "Configuración eliminada"});
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("remove-config"));
    }
  }
);

export const getRate = createAsyncThunk(
  "config/get-rate",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<any>("/rate/get");
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleFullScreenLoader(false));
    }
  }
);

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 430px;
  max-width: 430px;
  padding: 1rem;
  border-radius: 5px;
  background-color: ${({theme}) => theme["container"]};
`;

export const Grid = styled.div`
  gap: 1rem;
  max-width: 100%;
  padding-bottom: 0.5rem;
  display: flex;
  overflow-x: auto;
  align-items: flex-end;
  transform: rotateX(180deg);
`;

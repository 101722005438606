import {Formik} from "formik";
import {useAppDispatch} from "hooks";
import {Mail, Lock} from "react-feather";
import {Container, Dialog} from "./styled";
import {login} from "@redux/slices/auth/thunks";
import {initialValues, loginSchema} from "./form.schema";
import {Button, Column, Input, Logo, Typography} from "components";

interface LoginProps {}

export const Login: React.FC<LoginProps> = () => {
  const dispatch = useAppDispatch();
  return (
    <Container>
      <Column gap={0} alignItems="center">
        <Logo />
        <Typography
          color="#fff"
          fontSize={18}
          marginTop={15}
          fontFamily="Poppins-Medium"
        >
          Inicio de sesión
        </Typography>
        <Typography
          color="#fff"
          fontSize={16}
          textAlign="center"
          fontFamily="Poppins-Light"
        >
          Panel de administración
        </Typography>
      </Column>
      <Dialog>
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={(payload) => {
            dispatch(login(payload));
          }}
        >
          {({
            dirty,
            errors,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column alignItems="center" justifyContent="center" gap={15}>
                <Column gap={0} width="100%">
                  <Input
                    icon={Mail}
                    name="email"
                    type="email"
                    label="Correo"
                    touched={touched["email"]}
                    placeholder="correo@admin.com"
                    onBlur={handleBlur("email")}
                    onChange={handleChange("email")}
                    helperErrorText={errors["email"]}
                  />
                  <Input
                    icon={Lock}
                    name="password"
                    type="password"
                    label="Contraseña"
                    placeholder="*******"
                    touched={touched["password"]}
                    onBlur={handleBlur("password")}
                    onChange={handleChange("password")}
                    helperErrorText={errors["password"]}
                  />
                </Column>
                <Button
                  type="submit"
                  loaderId="login"
                  label="Iniciar sesión"
                  disabled={isDisabled}
                  onClick={() => handleSubmit()}
                  style={{width: "100%"}}
                />
              </Column>
            );
          }}
        </Formik>
      </Dialog>
    </Container>
  );
};

import {useEffect} from "react";
import {Container} from "./styled";
import {useAppSelector} from "hooks";
import {toggleBodyScroll} from "utils";
import {SpinnerLoader} from "components";
import {Fragment} from "react/jsx-runtime";

interface FullScreenLoaderProps {}

export const FullScreenLoader: React.FC<FullScreenLoaderProps> = () => {
  const {showFullScreenLoader} = useAppSelector("layout");

  useEffect(() => {
    toggleBodyScroll(showFullScreenLoader);
  }, [showFullScreenLoader]);

  return showFullScreenLoader ? (
    <Container>
      Logo
      <SpinnerLoader size={75} style={{marginTop: "-60px"}} />
    </Container>
  ) : (
    <Fragment />
  );
};

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
`;

export const Statistic = styled.div`
  display: grid;
  padding-bottom: 1rem;
  border-radius: 5px;
  grid-template-rows: 90px auto;
  background-color: ${({theme}) => theme["secondary"]};
`;

import {Formik} from "formik";
import {Key} from "react-feather";
import {Container} from "./styled";
import {useAppDispatch} from "hooks";
import {create, update} from "@redux/slices/config/thunks";
import {Input, Column, Button, Typography} from "components";
import {createLotterySchema, initialValues} from "./form.schema";

interface NewConfigProps {}

export const NewConfig: React.FC<NewConfigProps> = () => {
  const dispatch = useAppDispatch();

  return (
    <Container>
      <Column>
        <Typography fontSize={20} fontFamily="Poppins-SemiBold">
          Nueva configuración
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={createLotterySchema}
          onSubmit={(payload) => {
            if (payload["_id"]) {
              dispatch(update(payload));
            } else {
              dispatch(create(payload));
            }
          }}
        >
          {({
            dirty,
            errors,
            values,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column gap={5} width="100%">
                <Input
                  name="label"
                  label="Título"
                  value={values["label"]}
                  touched={touched["label"]}
                  placeholder="Configuración"
                  onBlur={handleBlur("label")}
                  onChange={handleChange("label")}
                  helperErrorText={errors["label"]}
                />
                <Input
                  icon={Key}
                  name="key"
                  label="Clave"
                  value={values["key"]}
                  touched={touched["key"]}
                  placeholder="Identificador"
                  onBlur={handleBlur("key")}
                  onChange={handleChange("key")}
                  helperErrorText={errors["key"]}
                />
                <Input
                  name="value"
                  label="Valor"
                  placeholder="Valor"
                  value={values["value"]}
                  touched={touched["value"]}
                  onBlur={handleBlur("value")}
                  onChange={handleChange("value")}
                  helperErrorText={errors["value"]}
                />
                <Column width="100%" alignItems="center" marginTop={18}>
                  <Button
                    type="submit"
                    label="Crear"
                    disabled={isDisabled}
                    loaderId="new-config"
                    style={{width: "100%"}}
                    onClick={() => handleSubmit()}
                  />
                </Column>
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};

import "chart.js/auto";
import {useEffect} from "react";
import {Container} from "./styled";
import {Bar, Line} from "react-chartjs-2";
import {useTheme} from "styled-components";
import {Line as UILine, ComponentLoader} from "components";

interface StatisticsProps {}

export const Statistics: React.FC<StatisticsProps> = () => {
  const {primary, secondary, black} = useTheme();
  // useEffect(() => {
  //   dispatch(get());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Container>
      <Line
        style={{height: "200px"}}
        data={{
          labels: [
            "Sorteo #1 — 25/10/24",
            "Sorteo #2 — 28/10/24",
            "Sorteo #3 — 30/10/24",
            "Sorteo #4 — 01/11/24",
            "Sorteo #5 — 03/11/24",
            "Sorteo #6 — 04/11/24",
          ],
          datasets: [
            {
              label: "Ganancias (USD)",
              backgroundColor: secondary,
              borderColor: secondary,
              borderWidth: 1,
              data: [1500, 500, 450, 2135, 490, 350],
            },
          ],
        }}
      />
      <UILine margin="2rem 0" />
      <Bar
        style={{height: "200px"}}
        data={{
          labels: [
            "Sorteo #1 — 25/10/24",
            "Sorteo #2 — 28/10/24",
            "Sorteo #3 — 30/10/24",
            "Sorteo #4 — 01/11/24",
            "Sorteo #5 — 03/11/24",
            "Sorteo #6 — 04/11/24",
          ],
          datasets: [
            {
              label: "Ventas de Tickets",
              backgroundColor: primary,
              borderColor: primary,
              borderWidth: 1,
              data: [100, 55, 45, 150, 50, 32],
            },
          ],
        }}
      />
      <UILine margin="2rem 0" />
      <Line
        style={{height: "200px"}}
        data={{
          labels: [
            "Junio 2024",
            "Julio 2024",
            "Agosto 2024",
            "Septiembre 2024",
            "Octubre 2024",
            "Noviembre 2024",
            "Diciembre 2024",
          ],
          datasets: [
            {
              label: "Usuarios registrados",
              backgroundColor: black,
              borderColor: black,
              borderWidth: 1,
              data: [150, 78, 249, 284, 578, 120, 387],
            },
          ],
        }}
      />
    </Container>
  );
};

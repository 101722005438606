import {useEffect} from "react";
import {useTheme} from "styled-components";
import {Edit, Map, Plus, Trash} from "react-feather";
import {CityForm, CountryForm, StateForm} from "./lib";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {
  getStates,
  getCities,
  getCountries,
} from "@redux/slices/location/thunks";
import {
  Row,
  Table,
  Column,
  Skeleton,
  IconButton,
  Typography,
  ComponentLoader,
} from "components";

interface LocationsProps {}

export const Locations: React.FC<LocationsProps> = () => {
  const {openModal} = useModal();
  const dispatch = useAppDispatch();
  const {container, primary, heart, white} = useTheme();
  const {countries, states, cities, request} = useAppSelector("location");

  useEffect(() => {
    dispatch(getCities());
    dispatch(getStates());
    dispatch(getCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row alignItems="flex-start" justifyContent="flex-start" gap="1rem">
      <ComponentLoader
        requestStatus={request["countries"]}
        skeleton={
          <Column width="100%">
            <Skeleton width={200} height="50px" />
            <Column gap={1} width="100%">
              <Skeleton count={5} width="100%" height="50px" />
            </Column>
          </Column>
        }
      >
        <Table
          data={countries}
          style={{width: "100%"}}
          columns="30px 120px 80px 80px 120px"
          emptyUI={{icon: <Map size={40} />, message: "No hay países creados"}}
          header={
            <Row gap={10}>
              <Typography fontSize={18} fontFamily="Poppins-Medium">
                Países
              </Typography>
              <IconButton
                size={30}
                toolTip="Agregar país"
                toolTipPosition="down"
                backgroundColor={primary}
                icon={<Plus color={white} />}
                onClick={() => openModal(<CountryForm />)}
              />
            </Row>
          }
          head={[
            {label: ""},
            {label: "Nombre"},
            {label: "Código"},
            {label: "Prefijo"},
            {label: "Acciones"},
          ]}
        >
          {({name, code, prefix}, key) => (
            <Table.Tr
              cursor="pointer"
              marginBottom={1}
              backgroundColor={container}
            >
              <Table.Td>
                <Typography fontSize={15}>{key + 1}</Typography>
              </Table.Td>
              <Table.Td gap={6} overflow="hidden">
                <Typography fontSize={15}>{name}</Typography>
              </Table.Td>
              <Table.Td>
                <Typography fontSize={16}>{code}</Typography>
              </Table.Td>
              <Table.Td>
                <Typography fontSize={16}>{prefix}</Typography>
              </Table.Td>
              <Table.Td gap={5}>
                <IconButton size={35} icon={<Edit />} onClick={() => {}} />
                <IconButton
                  size={35}
                  onClick={() => {}}
                  backgroundColor={heart}
                  icon={<Trash color={white} />}
                  toolTipStyle={{backgroundColor: heart}}
                />
              </Table.Td>
            </Table.Tr>
          )}
        </Table>
      </ComponentLoader>
      <ComponentLoader
        requestStatus={request["states"]}
        skeleton={
          <Column width="100%">
            <Skeleton width={200} height="50px" />
            <Column gap={1} width="100%">
              <Skeleton count={5} width="100%" height="50px" />
            </Column>
          </Column>
        }
      >
        <Table
          data={states}
          style={{width: "100%"}}
          columns="30px 100px 80px 120px"
          emptyUI={{icon: <Map size={40} />, message: "No hay estados creados"}}
          header={
            <Row gap={10}>
              <Typography fontSize={18} fontFamily="Poppins-Medium">
                Estados
              </Typography>
              <IconButton
                size={30}
                toolTip="Agregar país"
                toolTipPosition="down"
                backgroundColor={primary}
                icon={<Plus color={white} />}
                onClick={() => openModal(<StateForm />)}
              />
            </Row>
          }
          head={[
            {label: ""},
            {label: "Nombre"},
            {label: "Código"},
            {label: "Acciones"},
          ]}
        >
          {({name, code}, key) => (
            <Table.Tr
              cursor="pointer"
              marginBottom={1}
              backgroundColor={container}
            >
              <Table.Td>
                <Typography fontSize={15}>{key + 1}</Typography>
              </Table.Td>
              <Table.Td gap={6} overflow="hidden">
                <Typography fontSize={15}>{name}</Typography>
              </Table.Td>
              <Table.Td>
                <Typography fontSize={16}>{code}</Typography>
              </Table.Td>
              <Table.Td gap={5}>
                <IconButton size={35} icon={<Edit />} onClick={() => {}} />
                <IconButton
                  size={35}
                  onClick={() => {}}
                  backgroundColor={heart}
                  icon={<Trash color={white} />}
                  toolTipStyle={{backgroundColor: heart}}
                />
              </Table.Td>
            </Table.Tr>
          )}
        </Table>
      </ComponentLoader>
      <ComponentLoader
        requestStatus={request["cities"]}
        skeleton={
          <Column width="100%">
            <Skeleton width={200} height="50px" />
            <Column gap={1} width="100%">
              <Skeleton count={5} width="100%" height="50px" />
            </Column>
          </Column>
        }
      >
        <Table
          data={cities}
          style={{width: "100%"}}
          columns="30px 140px 110px"
          head={[{label: ""}, {label: "Nombre"}, {label: "Acciones"}]}
          header={
            <Row gap={10}>
              <Typography fontSize={18} fontFamily="Poppins-Medium">
                Ciudades
              </Typography>
              <IconButton
                size={30}
                toolTipPosition="down"
                toolTip="Agregar ciudad"
                backgroundColor={primary}
                icon={<Plus color={white} />}
                onClick={() => openModal(<CityForm />)}
              />
            </Row>
          }
          emptyUI={{
            icon: <Map size={40} />,
            message: "No hay ciudades creadas",
          }}
        >
          {({name}, key) => (
            <Table.Tr
              cursor="pointer"
              marginBottom={1}
              backgroundColor={container}
            >
              <Table.Td>
                <Typography fontSize={15}>{key + 1}</Typography>
              </Table.Td>
              <Table.Td gap={6} overflow="hidden">
                <Typography fontSize={15}>{name}</Typography>
              </Table.Td>
              <Table.Td gap={5}>
                <IconButton size={35} icon={<Edit />} onClick={() => {}} />
                <IconButton
                  size={35}
                  onClick={() => {}}
                  backgroundColor={heart}
                  icon={<Trash color={white} />}
                  toolTipStyle={{backgroundColor: heart}}
                />
              </Table.Td>
            </Table.Tr>
          )}
        </Table>
      </ComponentLoader>
    </Row>
  );
};

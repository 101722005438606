import {colors} from "styles";
import {getConfig} from "utils";
import {logOut} from "@redux/slices";
import {Container, Dot} from "./styled";
import {useTheme} from "styled-components";
import {ChevronDown, LogOut, Plus, UserPlus} from "react-feather";
import {useAppDispatch, useAppSelector, useModal, useToggleLive} from "hooks";
import {
  Row,
  Avatar,
  Toggle,
  Options,
  Confirm,
  IconButton,
  NewLottery,
  Typography,
  ChangeToLive,
} from "components";

interface TopBarProps {}

export const TopBar: React.FC<TopBarProps> = () => {
  const dispatch = useAppDispatch();
  const {toggleLive} = useToggleLive();
  const {user} = useAppSelector("auth");
  const {openModal, closeModal} = useModal();
  const {black05, heart, secondary, black, white} = useTheme();
  const {configs} = useAppSelector("config");
  const config = getConfig(configs, "on_live");
  const {value} = config;

  return (
    <Container>
      <Row>
        <Typography fontFamily="Poppins-Medium">En VIVO</Typography>
        <Toggle
          on={value === "1"}
          switchStyle={{
            backgroundColor:
              value === "1" ? colors["secondary"] : colors["heart"],
          }}
          onToggle={() => {
            const nextValue = value === "0" ? "1" : "0";
            if (nextValue === "0") {
              openModal(
                <Confirm
                  title="¿Seguro que deseas desactivar el EN VIVO?"
                  message="Esta acción afectará la vista de los usuarios en la aplicación"
                  actions={[
                    {
                      label: "Sí, desactivar",
                      loaderId: "remove-config",
                      style: {backgroundColor: heart},
                      onClick: () => toggleLive(nextValue),
                    },
                    {
                      label: "Cancelar",
                      onClick: () => closeModal(),
                      style: {backgroundColor: "#bcbcbcec"},
                    },
                  ]}
                />
              );
            } else {
              openModal(<ChangeToLive nextOnLiveValue={nextValue} />);
            }
          }}
        />
      </Row>
      <Options
        listStyle={{width: "200px", right: 0, top: "2.5rem"}}
        options={[
          {
            label: "Crear sorteo",
            icon: <Plus size={20} color={black} />,
            onClick: () => openModal(<NewLottery />),
          },
          // {
          //   label: "Crear usuario",
          //   icon: <UserPlus size={20} color={black} />,
          //   onClick: () => {},
          // },
        ]}
      >
        {({toggle}) => (
          <IconButton
            size={38}
            onClick={toggle}
            backgroundColor={secondary}
            icon={<Plus color={white} />}
          />
        )}
      </Options>
      <Dot />
      <Options
        listStyle={{width: "200px", right: 0, top: "2.5rem"}}
        options={[
          {
            label: "Cerrar sesión",
            typographyProps: {color: colors["heart"]},
            icon: <LogOut size={20} color={colors["heart"]} />,
            onClick: () => dispatch(logOut()),
          },
        ]}
      >
        {({toggle}) => (
          <Row gap={10} cursor="pointer" onClick={toggle}>
            <Row gap={10}>
              <Typography color={black05}>
                {user["name"]}, Administrador
              </Typography>
              <Avatar size={40} src={user["picture"]} />
            </Row>
            <ChevronDown color={black05} />
          </Row>
        )}
      </Options>
    </Container>
  );
};

import * as Yup from "yup";
import {CreateStatePayload} from "interfaces";

export const initialValues: CreateStatePayload = {
  name: "",
  code: "",
  countryId: "",
};

export const createCountrySchema = Yup.object().shape({
  name: Yup.string().required("Este campo es obligatorio"),
  code: Yup.string().required("Este campo es obligatorio"),
  countryId: Yup.string().required("Este campo es obligatorio"),
});

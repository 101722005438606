import {colors} from "styles";
import {fontSizeObj} from "./helper";
import {TypographyVariant} from "./types";
import {Children, Clickable} from "interfaces";
import {CSSProperties, MutableRefObject, ReactNode} from "react";

export type FontFamily =
  | "Poppins"
  | "Poppins-Bold"
  | "Poppins-Light"
  | "Poppins-Medium"
  | "Poppins-SemiBold";

export interface TypographyProps
  extends Children<ReactNode>,
    CSSProperties,
    Partial<Clickable> {
  className?: string;
  fontFamily?: FontFamily;
  variant?: TypographyVariant;
  ref?: MutableRefObject<any>;
}

export const Typography: React.FC<TypographyProps> = (props) => {
  const {
    ref,
    onClick,
    children,
    className,
    variant = "normal",
    ...style
  } = props;
  const {
    fontFamily = "Poppins",
    color = colors["black"],
    fontSize = fontSizeObj[variant],
  } = style;

  return (
    <span
      ref={ref}
      onClick={onClick}
      children={children}
      className={className}
      style={{fontFamily, color, fontSize, ...style}}
    />
  );
};

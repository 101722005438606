import {Route} from "interfaces";
import {
  Home,
  Users,
  Config,
  Ranking,
  Page404,
  Lottery,
  Payments,
  Locations,
  Lotteries,
  Testimonials,
  Login,
  Statistics,
} from "pages";

export const authRoutes: Route[] = [
  {
    path: "/",
    element: <Home />,
    label: "Inicio",
  },
  {
    path: "/users",
    element: <Users />,
    label: "Usuarios",
  },
  {
    path: "/history/lottery",
    element: <Lotteries />,
    label: "Historial de sorteos",
  },
  {
    path: "/testimonials",
    element: <Testimonials />,
    label: "Testimonios",
  },
  {
    path: "/config",
    element: <Config />,
    label: "Configuración",
  },
  {
    path: "/locations",
    element: <Locations />,
    label: "Ubicaciones",
  },
  {
    path: "/history/payments",
    element: <Payments />,
    label: "Pagos",
  },
  {
    path: "/history/ranking",
    element: <Ranking />,
    label: "Tabla de posiciones",
  },
  {
    path: "/history/lottery/:lotteryId",
    element: <Lottery />,
    label: "Sorteo",
  },
  {
    path: "/statistics",
    element: <Statistics />,
    label: "Estadísticas",
  },
  /************************************
   * 🛑 404
   ************************************/
  {
    path: "*",
    element: <Page404 />,
    label: "404",
  },
];

export const welcomeRoutes: Route[] = [
  {
    path: "/",
    element: <Login />,
    label: "Inicio de sesión",
  },

  /************************************
   * 🛑 404
   ************************************/
  {
    path: "*",
    element: <Page404 />,
    label: "404",
  },
];

import styled from "styled-components";

export const Container = styled.div``;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  align-items: flex-end;
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
`;
